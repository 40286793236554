var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ec-footer" }, [
    _c("div", { staticClass: "contact-info padding-top ec-container" }, [
      _c("div", { staticClass: "top-wrapper" }, [
        _c(
          "div",
          { staticClass: "customer-left" },
          [
            _c("div", { staticClass: "customer-item" }, [
              _c("img", {
                staticClass: "customer-item-logo",
                attrs: {
                  src: require("./img/icons/easycash.svg"),
                  alt: "easycashlogo",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "contact-ec" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.contact")) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.customerList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "customer-item customer-item-contact",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "customer-item-top",
                      style: { "border-left": "2px solid " + item.color },
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.title) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "contact" },
                        _vm._l(item.contact, function (it, ix) {
                          return _c(
                            "div",
                            {
                              key: ix,
                              staticClass: "contact-item",
                              style: {
                                "min-width": it.minWidth,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "customer-item-icon",
                                attrs: { src: it.icon },
                              }),
                              _vm._v(" "),
                              ix === 0
                                ? _c(
                                    "a",
                                    { attrs: { href: "tel:" + it.value } },
                                    [_vm._v(_vm._s(it.value))]
                                  )
                                : ix === 1
                                ? _c(
                                    "a",
                                    { attrs: { href: "mailto:" + it.value } },
                                    [_vm._v(_vm._s(it.value))]
                                  )
                                : _c("span", [_vm._v(_vm._s(it.value))]),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.customerServiceTimeTip
                        ? _c("div", { staticClass: "special-time" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.customerServiceTimeTip) +
                                "\n                        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showWhatsapp
                        ? _c("div", { staticClass: "contact whatsapp" }, [
                            _c(
                              "div",
                              {
                                staticClass: "contact-item",
                                style: {
                                  width: "100%",
                                  marginTop: "0",
                                },
                              },
                              [
                                (_vm.isHoverBorrow && index === 0) ||
                                (_vm.isHoverLend && index === 1)
                                  ? _c("div", { staticClass: "qr-wrap" }, [
                                      _c("img", {
                                        staticClass: "qr-code",
                                        attrs: { src: _vm.whatsappQr, alt: "" },
                                      }),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "whatsapp" }, [
                                  _c("img", {
                                    staticClass: "customer-item-icon",
                                    attrs: {
                                      src: require("./img/icons/whatsapp.svg"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: _vm.whatsappLink },
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.onMouseenter(index)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.onMouseleave(index)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.whatsappNumber))]
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "customer-item customer-item-social d-none d-md-block",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("footer.follow")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "social-icon" }, [
                  _c("div", {
                    staticClass: "icon icon-fa",
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("facebook")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "icon icon-in",
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("instagram")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "icon icon-tw",
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("twitter")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "icon icon-ti",
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("tiktok")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "icon icon-li",
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("linkedin")
                      },
                    },
                  }),
                ]),
              ]
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "customer-right" }, [
          _c(
            "div",
            { staticClass: "map-wrapper" },
            [
              _c(
                "ec-image",
                { attrs: { webp: require("./img/icons/map.webp") } },
                [
                  _c("img", {
                    staticClass: "d-none d-md-block",
                    attrs: {
                      src: require("./img/icons/map.png"),
                      alt: "gmaplogo",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("googlemap")
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "ec-image",
                { attrs: { webp: require("./img/icons/map-sm.webp") } },
                [
                  _c("img", {
                    staticClass: "d-block d-md-none",
                    attrs: {
                      src: require("./img/icons/map-sm.png"),
                      alt: "gmaplogo",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSocial("googlemap")
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "map-address" },
                _vm._l(
                  _vm.$t("footer.address.content"),
                  function (item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item) +
                          "\n                        "
                      ),
                    ])
                  }
                ),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "customer-item customer-item-social d-block d-md-none",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("footer.follow")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "social-icon" }, [
                _c("div", {
                  staticClass: "icon icon-fa",
                  on: {
                    click: function ($event) {
                      return _vm.handleSocial("facebook")
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "icon icon-in",
                  on: {
                    click: function ($event) {
                      return _vm.handleSocial("instagram")
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "icon icon-tw",
                  on: {
                    click: function ($event) {
                      return _vm.handleSocial("twitter")
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "icon icon-ti",
                  on: {
                    click: function ($event) {
                      return _vm.handleSocial("tiktok")
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "icon icon-li",
                  on: {
                    click: function ($event) {
                      return _vm.handleSocial("linkedin")
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-wrapper",
              style: {
                justifyContent:
                  _vm.$app.isIOS || _vm.$app.isAndroid ? "center" : "unset",
              },
            },
            [
              !_vm.$app.isIOS
                ? _c("app-store-button", {
                    staticClass: "btn",
                    attrs: { type: "google" },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.$app.isAndroid
                ? _c("app-store-button", {
                    staticClass: "btn",
                    attrs: { type: "apple-w" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "contact-info" }, [
      _c("div", { staticClass: "ec-container attention-text" }, [
        _c("div", { staticClass: "attention" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$t("footer.perhatian")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "attention-content" }, [
          _c(
            "div",
            { staticClass: "attention-content-item" },
            _vm._l(_vm.$t("footer.perhatianTextMap"), function (item, index) {
              return _c("div", { key: index }, [
                index < 4
                  ? _c("div", { staticClass: "notice-info" }, [
                      _c("span", [_vm._v(_vm._s(item))]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "attention-content-item" },
            _vm._l(_vm.$t("footer.perhatianTextMap"), function (item, index) {
              return _c("ul", { key: index }, [
                index >= 4
                  ? _c("li", { staticClass: "notice-info" }, [
                      _c("span", [_vm._v(_vm._s(item))]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "contact-info" }, [
      _c("div", { staticClass: "ec-container" }, [
        _c("div", { staticClass: "copy-right" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("footer.copyRight", [new Date().getFullYear()]))
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ec-container" }, [
      _c("div", { staticClass: "seperator" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ec-container" }, [
      _c("div", { staticClass: "seperator" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }